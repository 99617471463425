<template>
  <div class="kt-contact-edit pb-4">
    <!-- page header -->
    <div class="kt-page-header mt-4 pt-1 mb-3">
      <!-- back button -->
      <b-button
        size="sm"
        class="mr-3 kt-page-header__back-btn"
        variant="light"
        @click="onGoBack"
      >
        <b-icon icon="chevron-left"></b-icon>
      </b-button>
      <!-- title -->
      <h2 class="kt-page-header__title h4 mb-0">
        {{ pageTitleStringFormatted }}
      </h2>
    </div>

    <!-- divider -->
    <div class="w-100 border-top mt-1 mb-4"></div>

    <!-- contact form -->
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <!-- sexId -->
        <b-col cols="12">
          <b-form-group
            :invalid-feedback="sexIdInvalidFeedback"
            :state="sexIdState"
          >
            <b-form-radio-group
              ref="sexRadio"
              v-model="sexId"
              :options="sexOptions"
              value-field="id"
              text-field="localisedName"
              :state="sexIdState"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>

        <!-- firstName -->
        <b-col
          cols="12"
          md="4"
        >
          <InputTextFormatted
            v-model="firstName"
            :labelProp="$t('contacts.firstName')"
            :stateProp="firstNameState"
            :invalidFeedbackProp="firstNameInvalidFeedback"
            @blur="firstNameValidation = true"
            @submit="onSubmit"
          />
        </b-col>

        <!-- lastName -->
        <b-col
          cols="12"
          md="4"
        >
          <InputTextFormatted
            v-model="lastName"
            :labelProp="$t('contacts.lastName')"
            :stateProp="lastNameState"
            :invalidFeedbackProp="lastNameInvalidFeedback"
            @blur="lastNameValidation = true"
            @submit="onSubmit"
          />
        </b-col>

        <!-- code -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('contacts.code')"
            :invalid-feedback="codeInvalidFeedback"
            :state="codeState"
          >
            <b-form-input
              v-model="code"
              trim
              maxlength="50"
              :state="codeState"
              @blur="codeValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- registrationNumber -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('contacts.registrationNumber')"
            :invalid-feedback="registrationNumberInvalidFeedback"
            :state="registrationNumberState"
            class="form-group--optional"
          >
            <b-form-input
              v-model="registrationNumber"
              trim
              maxlength="50"
              :state="registrationNumberState"
              @blur="registrationNumberValidation = true"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- contact organisations -->
        <b-col cols="12">
          <b-form-group
            :label="$t('contacts.organisations')"
            :invalid-feedback="contactOrganisationsInvalidFeedback"
            :state="contactOrganisationsState"
          >
            <ContactOrganisations
              :contactOrganisationsProp="contactOrganisations"
              :editModeProp="editModeProp"
              hideLabelProp
              @contactOrganisationsUpdated="onUpdateContactOrganisations($event)"
              @isDefaultEdited="onIsDefaultEdited($event.target, $event.tempId, $event.value)"
              @alert="(variant, strings) => $emit('alert', variant, strings)"
            />
          </b-form-group>
        </b-col>

        <!-- contact contactInformations -->
        <b-col cols="12">
          <b-form-group
            :label="$t('contacts.contactInformations')"
            :invalid-feedback="contactInformationsInvalidFeedback"
            :state="contactInformationsState"
          >
            <ContactContactInformations
              :contactContactInformationsProp="contactContactInformations"
              :editModeProp="editModeProp"
              hideLabelProp
              @contactContactInformationsUpdated="onUpdateContactContactInformations($event)"
              @isDefaultEdited="onIsDefaultEdited($event.target, $event.tempId, $event.value)"
            />
          </b-form-group>
        </b-col>

        <!-- sendOptions -->
        <b-col cols="12">
          <b-form-group
            :label="$t('contacts.sendOptions')"
            class="mb-0"
          >
            <b-form-checkbox
              v-model="sendByPost"
              switch
            >
              {{ $t('contacts.sendByPost') }}
            </b-form-checkbox>
            <b-form-checkbox
              v-model="sendElectronically"
              switch
            >
              {{ $t('contacts.sendElectronically') }}
            </b-form-checkbox>
            <!-- warning : no sending mode selected -->
            <div
              v-show="!sendElectronically && !sendByPost"
              class="mb-2"
            >
              <div class="kt-badge kt-badge--yellow">
                <b-icon
                  icon="exclamation-circle"
                  class="kt-badge__icon"
                ></b-icon>
                <span class="kt-badge__text"><span class="font-weight-semi-bold">{{ $t("contactEdit.validations.noSendingMethodSelected") }}</span></span>
              </div>
            </div>
          </b-form-group>
          <!-- securedEmails -->
          <div style="padding-left: 36px">
            <b-form-group
              :invalid-feedback="securedEmailsInvalidFeedback"
              :state="securedEmailsState"
            >
              <label for="tags-securedEmails">{{ $t('contacts.securedAddresses') }}</label>
              <!-- :add-button-variant="$systemSettings.theme" -->
              <b-form-tags
                v-model="securedEmails"
                input-id="tags-securedEmails"
                separator=" ,;"
                remove-on-delete
                :disabled="!sendElectronically"
                :add-button-text="$t('formTag.addButtonText')"
                :duplicate-tag-text="$t('formTag.duplicateTagText')"
                :invalid-tag-text="$t('formTag.invalidTagText')"
                :placeholder="$t('formTag.placeholder')"
                :tag-remove-label="$t('formTag.tagRemoveLabel')"
                :tag-removed-label="$t('formTag.tagRemovedLabel')"
                :add-button-variant="'outline-secondary'"
                :tag-variant="'secondary'"
                :state="securedEmailsState"
                :tag-validator="(email) => {
                  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email);
                }"
              ></b-form-tags>
            </b-form-group>
          </div>
        </b-col>

        <!-- divider -->
        <b-col
          v-if="(!editMode || contactData) && isEnabled(['roleSpecific', 'chiefSecretary'])"
          cols="12"
        >
          <div class="w-100 border-top mt-1 mb-4"></div>
        </b-col>

        <!-- ContactAccount -->
        <b-col cols="12">
          <ContactAccount
            v-if="(!editMode || contactData) && isEnabled(['roleSpecific', 'chiefSecretary'])"
            class="mb-2"
            :contactEditModeProp="editMode"
            :contactIdProp="contactId || null"
            :contactDataProp="{
              isActive: isActive,
              contactContactInformations: contactContactInformations,
              contactOrganisations: contactOrganisations,
              isLocked: isLocked,
              email: email,
              phone: phone
            }"
            :displayedFieldsProp="[
              'email',
              'phone'
            ]"
            moduleModeProp
            @contactAccountEdited="(awaitingAccountData) => {
              isLocked = awaitingAccountData.isLocked,
              email = awaitingAccountData.email,
              phone = awaitingAccountData.phone
            }"
            @alert="(variant, strings) => $emit('alert', variant, strings)"
          />
        </b-col>

        <!-- divider -->
        <b-col cols="12">
          <div class="w-100 border-top mt-1 mb-4"></div>
        </b-col>

        <!-- submit -->
        <b-col cols="12">
          <b-button
            class="my-2"
            :variant="$systemSettings.theme"
            type="submit"
          >
            {{ finishedButtonStringFormatted }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// components
import InputTextFormatted from "@shared/views/Helpers/InputTextFormatted";
import ContactContactInformations from "@/views/Office/Contacts/ContactContactInformations";
import ContactOrganisations from "@/views/Office/Contacts/ContactOrganisations";
import ContactAccount from "@/views/Office/Contacts/ContactAccount";
// services
import commonServices from "@shared/services/API/commonServices";
// helpers
import userRights from "@/services/UI/userRights";
import error from "@shared/services/UI/error";
import phoneNumber from "@shared/services/UI/phoneNumber";
import address from "@shared/services/UI/address";
import { navigate } from "@/services/UI/vueRouterServices";

export default {
  components: {
    InputTextFormatted,
    ContactContactInformations,
    ContactOrganisations,
    ContactAccount
  },
  mixins: [userRights, error, phoneNumber, address],
  props: {
    contactIdProp: {
      type: Number,
      default: null
    },
    editModeProp: {
      type: Boolean
    }
  },
  data() {
    return {
      // general
      editMode: this.editModeProp,
      contactId: this.contactIdProp,
      contactData: null,
      // form account variables
      isLocked: false,
      email: "",
      phone: "",
      // form variables
      isActive: true,
      sexId: 2,
      firstName: "",
      lastName: "",
      code: "",
      registrationNumber: "",
      contactContactInformations: [],
      contactOrganisations: [],
      sendByPost: false,
      sendElectronically: true,
      securedEmails: [],
      // is validation active
      sexIdValidation: false,
      firstNameValidation: false,
      lastNameValidation: false,
      codeValidation: false,
      registrationNumberValidation: false,
      contactInformationsValidation: false,
      contactOrganisationsValidation: false,
      securedEmailsValidation: false
    };
  },
  computed: {
    // form validation
    sexIdState: function() {
      if (!this.sexIdValidation) return null;
      return this.sexId && this.sexId > 0 ? null : false;
    },
    sexIdInvalidFeedback: function() {
      return this.sexIdState === false ? this.$t("validationRules.required") : "";
    },
    firstNameState: function() {
      if (!this.firstNameValidation) return null;
      return this.firstName && this.firstName.length > 0 ? null : false;
    },
    firstNameInvalidFeedback: function() {
      return this.firstNameState === false ? this.$t("validationRules.required") : "";
    },
    lastNameState: function() {
      if (!this.lastNameValidation) return null;
      return this.lastName && this.lastName.length > 0 ? null : false;
    },
    lastNameInvalidFeedback: function() {
      return this.lastNameState === false ? this.$t("validationRules.required") : "";
    },
    codeState: function() {
      if (!this.codeValidation) return null;
      return this.code && this.code.length > 0 ? null : false;
    },
    codeInvalidFeedback: function() {
      return this.codeState === false ? this.$t("validationRules.required") : "";
    },
    registrationNumberState: function() {
      return null;
    },
    registrationNumberInvalidFeedback: function() {
      return "";
    },
    isEmptyContactAndOrganisation: function() {
      return this.contactContactInformationsActiveOnly.length === 0 && this.contactOrganisationsActiveOnly.length === 0;
    },
    contactInformationsState: function() {
      // is validation active
      if (!this.contactInformationsValidation) return null;

      // no contact AND no organisation
      if (this.isEmptyContactAndOrganisation) return false;
      // no default
      if (!this.getOrganisationsDefaultId() && !this.getContactInformationsDefaultId()) return false;
      // else
      return null;
    },
    contactInformationsInvalidFeedback: function() {
      // no error
      if (this.contactInformationsState === null) return "";

      // no contact AND no organisation
      if (this.isEmptyContactAndOrganisation) return this.$t("contactEdit.validations.contactInformationOrOrganisationRequired");
      // no default
      return this.$t("contactEdit.validations.isDefaultRequired");
    },
    contactOrganisationsState: function() {
      // is validation active
      if (!this.contactOrganisationsValidation) return null;

      // no contact AND no organisation
      if (this.isEmptyContactAndOrganisation) return false;
      // no organisation contactIformation
      for (const contactOrganisation of this.contactOrganisationsActiveOnly) {
        if (!contactOrganisation.contactInformationId) {
          return false;
        }
      }
      // no default
      if (!this.getOrganisationsDefaultId() && !this.getContactInformationsDefaultId()) return false;
      // else
      return null;
    },
    contactOrganisationsInvalidFeedback: function() {
      // no error
      if (this.contactOrganisationsState === null) return "";

      // no contact AND no organisation
      if (this.isEmptyContactAndOrganisation) return this.$t("contactEdit.validations.contactInformationOrOrganisationRequired");
      // no organisation contactIformation
      for (const contactOrganisation of this.contactOrganisationsActiveOnly) {
        if (!contactOrganisation.contactInformationId) {
          return this.$t("contactEdit.validations.organisationContactInformationRequired");
        }
      }
      // no default
      return this.$t("contactEdit.validations.isDefaultRequired");
    },
    areSecuredEmailsValid: function() {
      for (const email in this.securedEmails) {
        // test if invalid
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email)) {
          return false;
        }
        // test if duplicated
        let count = 0;
        for (const email2 in this.securedEmails) {
          if (email2 === email) {
            count++;
          }
          if (count > 1) return false;
        }
      }
      return true;
    },
    securedEmailsState: function() {
      if (!this.securedEmailsValidation) return null;
      if (!this.areSecuredEmailsValid) return false;
      return this.sendElectronically && this.securedEmails.length === 0 ? false : null;
    },
    securedEmailsInvalidFeedback: function() {
      if (this.securedEmailsState === null) return "";
      if (!this.areSecuredEmailsValid) return this.$t("validationRules.invalidEmail");
      return this.$t("validationRules.required");
    },

    // formatted variables
    contactContactInformationsActiveOnly: function() {
      return this.contactContactInformations.filter(item => item.status !== "deleted" && item.contactInformation.isActive === true);
    },
    contactOrganisationsActiveOnly: function() {
      return this.contactOrganisations.filter(item => item.status !== "deleted" && item.isActive === true);
    },
    pageTitleStringFormatted: function() {
      return this.editMode ? this.$t("contactEdit.pageTitle") : this.$t("contactNew.pageTitle");
    },
    finishedButtonStringFormatted: function() {
      return this.editMode ? this.$t("contactEdit.submitText") : this.$t("contactNew.submitText");
    },

    sexOptions: function() {
      return this.$systemSettings.sexes;
    }
  },
  async mounted() {
    // import data
    await this.importData();

    // auto-focus
    this.focusFirstElement();
  },
  methods: {
    focusFirstElement() {
      if (this.sexId !== null) {
        for (const child of this.$refs.sexRadio.$children) {
          if (child.value === this.sexId) {
            child.focus();
            break;
          }
        }
      } else {
        this.$refs.sexRadio.$children[0].focus();
      }
    },

    // load/refresh data
    async importData() {
      try {
        if (this.editModeProp) {
          // get contact
          const resServices = await commonServices.get("contacts", this.contactId);
          this.contactData = JSON.parse(JSON.stringify(resServices.data));
          // import data
          this.isActive = resServices.data.isActive;
          this.sexId = resServices.data.sexId;
          this.firstName = resServices.data.firstName;
          this.lastName = resServices.data.lastName;
          this.code = resServices.data.code;
          this.registrationNumber = resServices.data.registrationNumber;
          this.contactContactInformations = resServices.data.contactContactInformations;
          for (const contactContactInformation of this.contactContactInformations) {
            contactContactInformation.tempId = contactContactInformation.id;
            contactContactInformation.status = null;
          }
          this.contactOrganisations = resServices.data.contactOrganisations;
          for (var j in this.contactOrganisations) {
            this.contactOrganisations[j].tempId = this.contactOrganisations[j].id;
            this.contactOrganisations[j].status = null;
          }
          this.sendByPost = resServices.data.sendByPost;
          this.sendElectronically = resServices.data.sendElectronically;
          this.securedEmails = resServices.data.securedEmails;
          this.isLocked = resServices.data.isLocked;
          this.email = resServices.data.email;
          this.phone = resServices.data.phone;
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },

    // CONTACT INFORMATIONS AND ORGANISATIONS
    onUpdateContactContactInformations(data) {
      this.contactContactInformations = data;
      this.autoSelectDefaultContactInformationAndOrganisation();
      this.setContactInformationEditedStatus();
      this.contactInformationsValidation = true;
    },
    onUpdateContactOrganisations(data) {
      this.contactOrganisations = data;
      this.autoSelectDefaultContactInformationAndOrganisation();
      this.setContactInformationEditedStatus();
      this.contactOrganisationsValidation = true;
    },

    // helpers - contactInformation and organisations
    getContactInformationsDefaultId() {
      let defaultId = null;
      if (this.contactContactInformations.length) {
        for (const contactContactInformation of this.contactContactInformations) {
          if (contactContactInformation.isDefault) {
            defaultId = contactContactInformation.tempId;
            break;
          }
        }
      }
      return defaultId;
    },
    getOrganisationsDefaultId() {
      let defaultId = null;
      if (this.contactOrganisations.length) {
        for (const contactOrganisation of this.contactOrganisations) {
          if (contactOrganisation.isDefault) {
            defaultId = contactOrganisation.tempId;
            break;
          }
        }
      }
      return defaultId;
    },
    onIsDefaultEdited(target, tempId, value) {
      // clear all isDefault
      if (value) {
        this.clearDefaultContactInformation();
      }
      // set isDefault
      if (target === "contactContactInformations") {
        for (const contactContactInformation of this.contactContactInformations) {
          if (contactContactInformation.tempId === tempId) {
            // update the target value
            contactContactInformation.isDefault = value;
            break;
          }
        }
      } else {
        for (const contactOrganisation of this.contactOrganisations) {
          if (contactOrganisation.tempId === tempId) {
            // update the target value
            contactOrganisation.isDefault = value;
            break;
          }
        }
      }

      // handle default contact information
      this.autoSelectDefaultContactInformationAndOrganisation();
      this.setContactInformationEditedStatus();
      // activate the field validation
      this.contactInformationsValidation = true;
      this.contactOrganisationsValidation = true;
    },
    clearDefaultContactInformation() {
      if (this.contactContactInformations.length) {
        for (const contactContactInformation of this.contactContactInformations) {
          contactContactInformation.isDefault = false;
        }
      }
      if (this.contactOrganisations.length) {
        for (const contactOrganisation of this.contactOrganisations) {
          contactOrganisation.isDefault = false;
        }
      }
    },
    autoSelectDefaultContactInformationAndOrganisation() {
      const organisationLength = this.contactOrganisationsActiveOnly.length;
      const contactInformationLength = this.contactContactInformationsActiveOnly.length;
      if (
        organisationLength + contactInformationLength === 1 &&
        !this.getContactInformationsDefaultId() && !this.getOrganisationsDefaultId()
      ) {
        if (contactInformationLength) {
          this.contactContactInformationsActiveOnly[0].isDefault = true;
        } else if (organisationLength) {
          this.contactOrganisationsActiveOnly[0].isDefault = true;
        }
      }
    },
    setContactInformationEditedStatus() {
      if (this.contactData !== null) {
        for (const contactContactInformation of this.contactContactInformations) {
          if (contactContactInformation.status === null) {
            for (const originalContactContactInformation of this.contactData.contactContactInformations) {
              if (contactContactInformation.id === originalContactContactInformation.id) {
                if (contactContactInformation.isDefault !== originalContactContactInformation.isDefault) {
                  contactContactInformation.status = "edited";
                }
                break;
              }
            }
          }
        }
        for (const contactOrganisation of this.contactOrganisations) {
          if (contactOrganisation.status === null) {
            for (const originalContactOrganisation of this.contactData.contactOrganisations) {
              if (contactOrganisation.id === originalContactOrganisation.id) {
                if (contactOrganisation.isDefault !== originalContactOrganisation.isDefault) {
                  contactOrganisation.status = "edited";
                }
                break;
              }
            }
          }
        }
      }
    },

    // navigate to the entry page
    onGoBack() {
      navigate("office/contacts");
    },

    // submit
    onSubmit() {
      if (this.onValidateContact() !== false) this.onSaveContact();
    },
    onValidateContact() {
      this.sexIdValidation = true;
      this.firstNameValidation = true;
      this.lastNameValidation = true;
      this.codeValidation = true;
      this.registrationNumberValidation = true;
      this.contactInformationsValidation = true;
      this.contactOrganisationsValidation = true;
      this.securedEmailsValidation = true;

      return !(
        this.sexIdState === false ||
        this.firstNameState === false ||
        this.lastNameState === false ||
        this.codeState === false ||
        this.registrationNumberState === false ||
        this.contactInformationsState === false ||
        this.contactOrganisationsState === false ||
        this.securedEmailsState === false
      );
    },
    async onSaveContact() {
      try {
        const contactContactInformations = this.contactContactInformations.map((contactContactInformation) => {
          return {
            status: contactContactInformation.status,
            id: contactContactInformation.id,
            isDefault: contactContactInformation.isDefault,
            contactInformationId: contactContactInformation.contactInformationId,
            contactInformation: contactContactInformation.contactInformation
          };
        });
        const contactOrganisations = this.contactOrganisations.map((contactOrganisation) => {
          return {
            status: contactOrganisation.status,
            id: contactOrganisation.id,
            isActive: contactOrganisation.isActive,
            isDefault: contactOrganisation.isDefault,
            organisationId: contactOrganisation.organisationId,
            contactInformationId: contactOrganisation.contactInformationId
          };
        });
        const form = {
          sexId: this.sexId,
          firstName: this.firstName,
          lastName: this.lastName,
          code: this.code,
          registrationNumber: this.registrationNumber,
          contactContactInformations: contactContactInformations,
          contactOrganisations: contactOrganisations,
          sendByPost: this.sendByPost,
          sendElectronically: this.sendElectronically,
          securedEmails: this.securedEmails,
          isLocked: this.isLocked,
          email: this.email,
          phone: this.phone
        };

        if (this.editMode) {
          const res = await commonServices.put("contacts", form, this.contactId);
          if (res.data === true) {
            // success message
            this.successSave();
          } else if (res.data.error === "emailInUse") {
            this.$emit("alert", "danger", {
              title: this.$t("contactAccountEdit.notifications.emailInUseTitle"),
              message: this.$t("contactAccountEdit.notifications.emailInUseText")
            });
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("contactEdit.notifications.editionErrorTitle"),
              message: this.$t("contactEdit.notifications.editionErrorText")
            });
          }
        } else {
          form.isActive = true;
          // account creation data
          form.isLocked = false;
          const res = await commonServices.post("contacts", form);
          if (res.data.id) {
            // success message
            this.successSave();
          } else if (res.data.error === "emailInUse") {
            this.$emit("alert", "danger", {
              title: this.$t("contactAccountEdit.notifications.emailInUseTitle"),
              message: this.$t("contactAccountEdit.notifications.emailInUseText")
            });
          } else {
            this.$emit("alert", "danger", {
              title: this.$t("contactNew.notifications.additionErrorTitle"),
              message: this.$t("contactNew.notifications.additionErrorText")
            });
          }
        }
      } catch (err) {
        this.handleErrors(err);
      }
    },
    successSave() {
      if (this.editMode) {
        this.$emit("alert", "success", {
          title: this.$t("contactEdit.notifications.editedTitle"),
          message: this.$t("contactEdit.notifications.editedText")
        });
      } else {
        this.$emit("alert", "success", {
          title: this.$t("contactNew.notifications.addedTitle"),
          message: this.$t("contactNew.notifications.addedText")
        });
      }
      navigate("office/contacts");
    }
  }
};
</script>
